export default {
  styles: {
    ol: {
      marginLeft: "2rem",
    },
    ul: {
      marginLeft: "2rem",
    },
  },
}
